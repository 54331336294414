import { signInWithNearWallet, signOutNearWallet } from './near-api';
import React from 'react';

export function SignInPrompt() {
  return (
    <main>
      <h1>
          Welcome to NEAR Avatar App!
      </h1>
      <p>
        This smart-contract is based on Avataaars Library by Pablo Stanley, it generates a unique NFT for a given NEAR account.
        Every image from NFT is generated on the fly and is not stored on the NEAR blockchain.
          The NFT contract uses NEAR-SDK-JS to utilize the existing <a href={"https://github.com/HB0N0/AvataaarsJs"}>generative Avatar library</a>.
      </p>
          <p>To view and mint your avatar you need to sign in using the NEAR Wallet. It is very simple,
        just use the button below.
      </p>
      <br/>
      <p style={{ textAlign: 'center' }}>
        <button onClick={signInWithNearWallet}>Sign in with NEAR Wallet</button>
      </p>
    </main>
  );
}

export function SignOutButton({accountId}) {
  return (
    <button style={{ float: 'right', fontSize: '10px' }} onClick={signOutNearWallet}>
      Sign out
    </button>
  );
}

export function EducationalText() {
  return (
    <>
      <p>
        Look at that! A Hello World app! This greeting is stored on the NEAR blockchain. Check it out:
      </p>
      <ol>
        <li>
          Look in <code>src/App.js</code> and <code>src/utils.js</code> – you'll see <code>get_greeting</code> and <code>set_greeting</code> being called on <code>contract</code>. What's this?
        </li>
        <li>
          Ultimately, this <code>contract</code> code is defined in <code>assembly/main.ts</code> – this is the source code for your <a target="_blank" rel="noreferrer" href="https://docs.near.org/docs/develop/contracts/overview">smart contract</a>.</li>
        <li>
          When you run <code>npm run dev</code>, the code in <code>assembly/main.ts</code> gets deployed to the NEAR testnet. You can see how this happens by looking in <code>package.json</code> at the <code>scripts</code> section to find the <code>dev</code> command.</li>
      </ol>
      <hr />
      <p>
        To keep learning, check out <a target="_blank" rel="noreferrer" href="https://docs.near.org">the NEAR docs</a> or look through some <a target="_blank" rel="noreferrer" href="https://examples.near.org">example apps</a>.
      </p>
    </>
  );
}
